import { Alert, Button, Form, Input } from 'antd';
import { navigate } from 'gatsby';
import React, { useState } from 'react';
import { useLocation } from '@reach/router';
import EmptyLayout from '../../components/layout/EmptyLayout';
import SearchEngineOptimization from '../../components/utility/seo';
import { useCustomer } from '../../hooks/use-customer';

interface formValue {
  password: string;
}

const UpdatePassword = () => {
  const [form] = Form.useForm<formValue>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const {
    actions: { resetPassword },
  } = useCustomer();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const token = params.get('token');
  const email = params.get('email');
  const onSubmit = async (formValue) => {
    try {
      setLoading(true);
      await resetPassword(email, formValue.password, token);
      navigate('/update-password/success');
    } catch (_) {
      console.log('error', _);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <EmptyLayout title={'เปลี่ยนรหัสผ่าน'}>
      <SearchEngineOptimization title="Reset password" />
      <span className="text-light-primary">
        กรุณากรอกรหัสผ่านใหม่ด้านล่างนี้และไม่ซ้ำกับรหัสผ่านปัจจุบัน
      </span>
      <Form
        form={form}
        onFinish={onSubmit}
        disabled={loading}
        layout="vertical"
        autoComplete="off"
      >
        <Form.Item
          name="password"
          label="รหัสผ่านใหม่"
          required={false}
          rules={[
            { required: true, message: 'กรุณากรอกรหัสผ่าน' },
            { min: 8, message: 'กรุณากรอกรหัสผ่านอย่างน้อย 8 ตัวอักษร' },
          ]}
        >
          <Input.Password placeholder="ระบุรหัสผ่านอย่างน้อย 8 ตัวอักษร" />
        </Form.Item>
        <Button type="primary" loading={loading} htmlType="submit" block>
          เปลี่ยนรหัสผ่าน
        </Button>
        {error && (
          <Alert
            className="!items-baseline !text-light-title !mt-4"
            message="ไม่สามารถเปลี่ยนรหัสผ่านได้ กรุณาลองใหม่อีกครั้ง หรือติดต่อฝ่าย IT ของเราหากคุณยังพบปัญหาอยู่"
            type="error"
            showIcon
          />
        )}
      </Form>
    </EmptyLayout>
  );
};

export default UpdatePassword;
